import React from "react";
import { useTranslation } from "react-i18next";
import ActionMenuBox from "components/menu/action-menu/ActionMenuBox";
import ActionMenuItem from "components/menu/action-menu/ActionMenuItem";

const MenuTrigger = () => {
  const { t } = useTranslation();
  // this function will use when we implement Edit and Delete feature
  const onClickHandler = () => {
    console.log("Event");
  };
  return (
    <ActionMenuBox>
      <ActionMenuItem title={t("Edit")} onClickAction={onClickHandler} />
      <div className="separator my-0"></div>
      <ActionMenuItem title={t("Delete")} onClickAction={onClickHandler} />
    </ActionMenuBox>
  );
};

export default MenuTrigger;
