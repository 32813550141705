import React, {
  createContext,
  useContext,
  useRef,
  ReactNode,
  useMemo,
  useState,
} from "react";
import { getCurrencies } from "services/commonAPIs";

type WithChildren = {
  children: ReactNode;
};
interface OptionsType {
  value: string;
  label: string;
}
interface CommonContextType {
  commonModalRef: React.RefObject<HTMLDivElement>;
  currencies: OptionsType[];
  fetchCurrenciesHandler: () => void;
  showSuccess: boolean;
  successMessage: string;
  showSuccessToast: (message: string) => void;
  hideSuccessToast: () => void;

  showError: boolean;
  errorMessage: string;
  showErrorToast: (message: string) => void;
  hideErrorToast: () => void;
}

export const CommonContext = createContext<CommonContextType>({
  commonModalRef: { current: null },
  currencies: [],
  fetchCurrenciesHandler: () => {},
  showSuccess: false,
  successMessage: "",
  showSuccessToast: (message: string) => {},
  hideSuccessToast: () => {},

  showError: false,
  errorMessage: "",
  showErrorToast: (message: string) => {},
  hideErrorToast: () => {},
});

export const useCommonContext = () => {
  return useContext(CommonContext);
};

export const CommonProvider: React.FC<WithChildren> = ({ children }) => {
  const commonModalRef = useRef<HTMLDivElement | null>(null);

  const [currencies, setCurrencies] = useState<OptionsType[]>([]);

  const fetchCurrenciesHandler = async () => {
    if (currencies.length <= 0) {
      try {
        await getCurrencies()
          .then((response: any) => {
            setCurrencies(
              response.data.data.map((item: any) => ({
                value: item.guid,
                label: item.code,
              }))
            );
          })
          .catch((error: any) => {
            console.log(error);
          });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const showSuccessToast = (message: string) => {
    setSuccessMessage(message);
    setShowSuccess(true);
  };

  const hideSuccessToast = () => setShowSuccess(false);

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const showErrorToast = (message: string) => {
    setErrorMessage(message);
    setShowError(true);
  };

  const hideErrorToast = () => setShowError(false);

  const value = useMemo(
    () => ({
      commonModalRef,
      currencies,
      fetchCurrenciesHandler,
      showSuccess,
      successMessage,
      showSuccessToast,
      hideSuccessToast,

      showError,
      errorMessage,
      showErrorToast,
      hideErrorToast,
    }),
    // eslint-disable-next-line
    [
      commonModalRef,
      currencies,
      showSuccess,
      successMessage,
      showSuccessToast,
      hideSuccessToast,

      showError,
      errorMessage,
      showErrorToast,
      hideErrorToast,
    ]
  );

  return (
    <CommonContext.Provider value={value}>{children}</CommonContext.Provider>
  );
};
