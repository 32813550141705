import React, { useEffect, useRef } from "react";
import { Tab } from "bootstrap";
import { MenuComponent } from "../assets/ts/components";
import { ThemeModeComponent } from "../assets/ts/layout";

import { useLayout } from "./core";

export function MasterMenuComponent() {
  const { config } = useLayout();
  const isFirstRun = useRef(true);
  const pluginsInitialization = () => {
    isFirstRun.current = false;
    ThemeModeComponent.init();
    setTimeout(() => {
      MenuComponent.bootstrap();
      document.querySelectorAll('[data-bs-toggle="tab"]').forEach((tab) => {
        Tab.getOrCreateInstance(tab);
      });
    }, 500);
  };

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      pluginsInitialization();
    }
  }, [config]);

  return <></>;
}
