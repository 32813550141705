import clsx from "clsx";
import { KTIcon } from "helpers";
import React, { FC } from "react";
interface Props {
  loading?: boolean;
  label: string;
  type?: "submit" | "button" | "reset";
  styleClass?: string;
  disabled?: boolean;
  iconName?: string;
  padding?: string;
  onClick?: any;
}
const FilledButtons: FC<Props> = ({
  label,
  styleClass = "",
  type = "button",
  iconName,
  padding,
  disabled = false,
  ...rest
}) => {
  return (
    <button
      className={clsx(
        "n-primary-filled-btn" + styleClass,
        padding ? "px-2 py-1" : "px-4 py-2"
      )}
      type={type}
      disabled={disabled}
      {...rest}
    >
      <span className="d-flex items-align-center justify-content-center gap-1 s-3 ">
        {iconName && (
          <KTIcon iconName={iconName} className="fs-3 n-filled-btn-icon" />
        )}
        {label}
      </span>
    </button>
  );
};

export default FilledButtons;
