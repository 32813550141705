import React, { Dispatch, FC, SetStateAction, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { useAuthValidationRules } from "helpers/validation-schema/AuthValidationRules";
import { useFormik } from "formik";
import { object } from "yup";
import jobposter from "assets/images/createJobPoster.svg";
import { CreateJobFormContainer } from "../Jobs/components/ui-components/CreateJobFormLayout";

import CloseCrossIcons from "components/base/CloseCrossIcons";
import FullModalTitle from "components/base/FullModalTitle";
import SelectInput from "components/form/SelectInput";
import MultiSelectField from "components/form/MultiSelectField";
import FilledGrayButton from "components/button/FilledGrayButton";
import FilledButtons from "components/button/FilledButtons";
import InputTextField from "components/form/InputTextField";
const jobList = [
  { value: "option 1", label: "Option 1" },
  { value: "option 2", label: "Option 2" },
  { value: "option 3", label: "Option 3" },
  { value: "option 4", label: "Option 4" },
  { value: "option 5", label: "Option 5" },
];

const candidateList = [
  { guid: "option1", displayName: "Mehul Kurkute" },
  { guid: "option2", displayName: "Viraj Dhimmar" },
  { guid: "option3", displayName: "Vishal Gupta" },
];

const interviewerList = [
  { guid: "option3", displayName: "Ninand Sir" },
  { guid: "option1", displayName: "Nisarg Sir" },
  { guid: "option2", displayName: "Harish Sir" },
];

interface Props {
  setModalToggle?: Dispatch<SetStateAction<boolean>>;
}

const ScheduleInterview: FC<Props> = ({ setModalToggle = () => {} }) => {
  const { t } = useTranslation();
  const validationRules = useAuthValidationRules();
  const [currentStep, setCurrentStep] = useState(1);
  const numSteps = 3;

  const initialValues: any = {
    jobDisplayName: "",
    selectedCandidates: [],
  };

  const createValidationSchema = () => {
    const schema: any = {
      jobDisplayName: validationRules.jobNameValidationSchema,
    };

    return object(schema);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: createValidationSchema(),
    onSubmit: async (values, { setSubmitting }) => {
      console.log(values);
    },
  });

  const onChangeSelectedField = (target: any) => {
    formik.setFieldValue(target.target.name, {
      value: target.target.value,
      label: target.target.label,
    });
  };

  async function nextStepper() {
    setCurrentStep((prevStep) => prevStep + 1);
  }
  const backStep = () => {
    setCurrentStep((prevStep: any) =>
      prevStep >= numSteps ? 1 : prevStep - 1
    );
  };
  return (
    <CreateJobFormContainer>
      <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 rounded-0 card order-lg-1">
        <div className="d-flex w-100 justify-content-end">
          <CloseCrossIcons setModalToggle={setModalToggle} />
        </div>
        <div className="d-flex flex-center justify-content-center h-100 w-100 flex-column  ">
          <FullModalTitle title="Schedule interview" />
          <div className="w-100">
            {currentStep === 1 && (
              <div className="p-10">
                {" "}
                <SelectInput
                  label={t("Job Name")}
                  labelClass="required"
                  name="selectedJobCategory"
                  selectedOption={formik.values.selectedJobCategory}
                  setSelectedOption={onChangeSelectedField}
                  options={jobList}
                  helperText={
                    formik.touched.selectedJobCategory &&
                    formik.errors.selectedJobCategory
                  }
                  error={
                    formik.touched.selectedJobCategory &&
                    Boolean(formik.errors.selectedJobCategory)
                  }
                />
                <MultiSelectField
                  label={t("Candidates")}
                  labelClass="required"
                  field={{
                    name: "selectedCandidates",
                    value: formik.values.selectedCandidates,
                  }}
                  form={formik}
                  options={candidateList}
                />
              </div>
            )}

            {currentStep === 2 && (
              <>
                {formik.values.selectedCandidates.map(
                  (item: any, index: number) => (
                    <div
                      key={item.displayName}
                      className="row align-items-start"
                    >
                      <div className="col-3 px-2">
                        <InputTextField
                          label={"Candidate " + index}
                          value={item.displayName}
                        />
                      </div>
                      <div className="col-4 px-2">
                        <MultiSelectField
                          label={t("Interviewer")}
                          labelClass="required"
                          field={{
                            name: "selectedInterviewer",
                            value: formik.values.selectedInterviewer,
                          }}
                          form={formik}
                          options={interviewerList}
                        />
                      </div>
                      <div className="col-5  px-2 d-flex">
                        <div className="row p-0">
                          <div className="col-6">
                            <InputTextField
                              label={t("StartTime")}
                              name="StartTime"
                              labelClass="required"
                              type="datetime-local"
                              value=""
                              onChange={formik.handleChange}
                              helperText={
                                formik.touched.StartTime &&
                                formik.errors.StartTime
                              }
                              error={
                                formik.touched.StartTime &&
                                Boolean(formik.errors.StartTime)
                              }
                            />
                          </div>
                          <div className="col-6 px-2">
                            <InputTextField
                              label={t("EndTime")}
                              name="EndTime"
                              labelClass="required"
                              type="datetime-local"
                              value=""
                              onChange={formik.handleChange}
                              helperText={
                                formik.touched.EndTime && formik.errors.EndTime
                              }
                              error={
                                formik.touched.EndTime &&
                                Boolean(formik.errors.EndTime)
                              }
                            />
                          </div>{" "}
                        </div>{" "}
                      </div>
                    </div>
                  )
                )}
              </>
            )}

            <div className="d-flex justify-content-end pt-4 gap-4">
              <div>
                {currentStep > 1 && (
                  <FilledGrayButton onClick={backStep} label={t("BACK")} />
                )}
              </div>
              <div>
                {currentStep === 2 ? (
                  <FilledButtons
                    onClick={formik.submitForm}
                    label={t("Schedule")}
                  />
                ) : (
                  <FilledButtons onClick={nextStepper} label={t("NEXT")} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex flex-lg-row-fluid  w-lg-50 bgi-size-cover bg-gray-300 bgi-position-center align-items-center justify-content-center order-1 order-lg-2 ">
        <img src={jobposter} className="h-250px" alt="poster" />
      </div>
      <ToastContainer />
    </CreateJobFormContainer>
  );
};

export default ScheduleInterview;
