import { useState, useEffect } from "react";
import apiInterceptor from "services/apiInterceptor";
const limit = 4;
interface CursorType {
  rel: string;
  value: string;
}

interface UseFetchParams {
  url: string;
  initialQueryParams?: any;
  perPageRecord?: number;
}

interface UseFetchResult<T> {
  data: T[];
  isLoading: boolean;
  currentPage: number;
  totalPages: number;
  cursors: CursorType[];
  fetchData: (page?: number | null, cursor?: string | null) => Promise<void>;
}

function useFetch<T>({ url }: UseFetchParams): UseFetchResult<T> {
  const [data, setData] = useState<T[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [cursors, setCursors] = useState<CursorType[]>([]);

  const fetchData = async (page?: number | null, cursor?: string | null) => {
    try {
      const cursorParam = cursor ? `&cursor=${cursor}` : "";
      const pageNo = page ? `&pageNo=${page - 1}` : "";

      await apiInterceptor
        .get(`${url}?limit=${limit}${cursorParam}${pageNo}`)
        .then((response) => {
          setData(response.data.data);
          setCursors(response.data.cursors);
          setTotalPages(Math.ceil(response.data.total / limit));
          setCurrentPage(response.data.offset / limit + 1);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Please try again later:", error);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);
  return { data, isLoading, currentPage, totalPages, cursors, fetchData };
}

export default useFetch;
