import React, { FC } from "react";
import { RxCross1 } from "react-icons/rx";
import fileUpload from "assets/svg/file-upload.svg";

import excelIcon from "assets/svg/excel-icon.svg";
const UploadedFile: FC<{
  fileName: string;
  removeUploadedFile: () => void;
  isExcel?: boolean;
}> = ({ fileName, removeUploadedFile, isExcel = false }) => {
  return (
    <div className="my-2 d-flex   justify-content-between  gap-2 align-items-center p-4 radius-12 n-bg-primary-opacity-10 n-border-2-primary-opacity-10">
      <div>
        {isExcel ? (
          <img alt="icon-file" src={excelIcon} className="h-25px" />
        ) : (
          <img alt="icon-file" src={fileUpload} className="h-25px" />
        )}
        <span className="px-4"> {fileName}</span>
      </div>
      <RxCross1 className="cursor-pointer fs-20" onClick={removeUploadedFile} />
    </div>
  );
};

export default UploadedFile;
