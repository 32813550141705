import React, { ReactNode } from "react";

const ActionMenuBox = ({ children }: { children: ReactNode }) => {
  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4"
      data-kt-menu="true"
    >
      {children}
    </div>
  );
};

export default ActionMenuBox;
