import { WithChildren } from "helpers";
import React, { FC } from "react";
type Props = { width?: string };
const Table: FC<WithChildren & Props> = ({ children, width = "auto" }) => {
  return (
    <div className="table-responsive">
      <table
        style={{ width: width }}
        className="table align-middle table-hover table-row-bordered fs-6 gy-3 dataTable no-footer"
      >
        {children}
      </table>
    </div>
  );
};

export default Table;
