import { useCommonContext } from "hooks‬/CommonContext";

import React from "react";
import Toast from "react-bootstrap/Toast";
import { FaCheckCircle } from "react-icons/fa"; // Importing checkmark icon
import { IoMdClose } from "react-icons/io";

const SuccessToast = () => {
  const { showSuccess, successMessage, hideSuccessToast } = useCommonContext();

  return (
    <Toast
      onClose={hideSuccessToast}
      show={showSuccess}
      delay={3000}
      autohide
      style={{
        position: "fixed",
        top: "70px",
        right: "15px",
        backgroundColor: "#E6FFFA",
        borderLeft: "5px solid #17a589",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        zIndex: 1050,
        maxWidth: "280px",
      }}
    >
      <Toast.Header
        className="py-4"
        closeButton={false}
        style={{ borderBottom: "none" }}
      >
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex align-items-center">
            <FaCheckCircle
              color="#17a589"
              className="fs-20 p-0  "
              style={{ marginRight: "10px" }}
            />
            <strong
              className="me-auto fs-14"
              style={{ color: "#333", fontWeight: "500" }}
            >
              {successMessage}
            </strong>
          </div>
          <button
            type="button"
            className=" fs-14 p-0"
            onClick={hideSuccessToast}
            aria-label="Close"
            style={{
              marginLeft: "auto",
              background: "transparent",
              border: "none",
            }}
          >
            <IoMdClose className="fs-1" />
          </button>
        </div>
      </Toast.Header>
    </Toast>
  );
};

export default SuccessToast;
