import { WithChildren } from "helpers";
import React, { Dispatch, FC, SetStateAction } from "react";
import CloseModalCrossIcon from "./CloseModalCrossIcon";
import { useCommonContext } from "hooks‬/CommonContext";
interface Props {
  title: string;
  modalToggle?: boolean;
  setModalToggle?: Dispatch<SetStateAction<boolean>>;
}
const ModalContainer: FC<Props & WithChildren> = ({
  children,
  setModalToggle = () => {},
  modalToggle = false,
  title,
}) => {
  const { commonModalRef } = useCommonContext();
  return (
    <div
      className="modal fade"
      tabIndex={-1}
      id="modal_popup"
      ref={commonModalRef}
    >
      <div className="modal-dialog modal-lg  w-lg-600px  modal-dialog-scrollable">
        <div className="modal-content p-5">
          <div className="modal-header border-0 p-5">
            <div className="modal-title fs-20">{title}</div>

            <button
              className="ms-2 border-0 bg-transparent"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setModalToggle(!modalToggle)}
            >
              <CloseModalCrossIcon />
            </button>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default ModalContainer;
