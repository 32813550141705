import Select, { MultiValue, ActionMeta } from "react-select";
interface OptionType {
  guid: string;
  displayName: string;
}

const MultiSelectField = ({
  field,
  form,
  options,
  label = "",
  isHideBorder = false,
  id = "",
  labelClass = "",
}: {
  field: { name: string; value: MultiValue<OptionType> };
  form: any;
  options: OptionType[];
  label?: string;
  isHideBorder?: boolean;
  id?: string;
  labelClass?: string;
}) => {
  const onChange = (
    selectedOptions: MultiValue<OptionType>,
    _actionMeta: ActionMeta<OptionType>
  ) => {
    form.setFieldValue(field.name, selectedOptions);
  };

  return (
    <div className={`fv-row ${!isHideBorder && "mb-5"}`}>
      {label && (
        <label
          htmlFor={id}
          className={"form-label text-dark fs-6 m-2 text-muted " + labelClass}
        >
          {label}
        </label>
      )}
      <Select
        isMulti
        name={field.name}
        value={field.value}
        options={options}
        onChange={onChange}
        getOptionLabel={(option: OptionType) => option.displayName}
        getOptionValue={(option: OptionType) => option.guid}
        classNames={{
          control: () => "p-0 outline-select",
        }}
        classNamePrefix="react-select"
        placeholder=""
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            borderColor:
              form.errors[field.name] && form.touched[field.name]
                ? "red "
                : "#dbdfe9",
            boxShadow: "none",
            border: "1",
          }),
        }}
        theme={(theme) => ({
          ...theme,
          borderRadius: 6,
          colors: {
            ...theme.colors,
            primary: "grey",
          },
        })}
        components={{
          IndicatorSeparator: () => null,
        }}
        isClearable={false}
      />
      {form.errors[field.name] && form.touched[field.name] && (
        <div className="text-danger">
          {Array.isArray(form.errors[field.name])
            ? form.errors[field.name].join(", ")
            : form.errors[field.name]}
        </div>
      )}
    </div>
  );
};

export default MultiSelectField;
