import React, { FC } from "react";
import "./Stepper.css";

interface Props {
  currentStep: any;
  setCurrentStep: any;
}

const Stepper: FC<Props> = ({ currentStep, setCurrentStep }) => {
  return (
    <div>
      <div className="stepper-horizontal" id="stepper1">
        {stepsData.map((step, index) => {
          const stepNum = index + 1;
          return (
            <div
              key={stepNum}
              className={`step ${stepNum === currentStep ? "editing" : ""} ${
                stepNum < currentStep ? "done" : ""
              }`}
            >
              <div className="step-circle">
                <span
                  className={`${
                    stepNum < currentStep ? " " : "ni-text-primary fw-normal"
                  }`}
                >
                  {stepNum}
                </span>
              </div>
              <div
                className={`step-title fw-normal ${
                  stepNum < currentStep ? "ni-text-primary fw-normal" : " "
                }`}
              >
                {step.title}
              </div>
              <div className="step-bar-left"></div>
              <div className="step-bar-right"></div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const stepsData = [
  { title: "Job Details" },
  { title: "Position Details" },
  { title: "Job Description" },
];

export default Stepper;
