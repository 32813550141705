import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import multiFileUpload from "assets/svg/multi-file-upload.svg";
import { useTranslation } from "react-i18next";
import SelectInput from "components/form/SelectInput";
import FilledGrayButton from "components/button/FilledGrayButton";
import FilledButtons from "components/button/FilledButtons";
import { uploadResumeToCreateCandidate } from "services/candidates";
import UploadedFile from "components/ui-components/UploadedFile";
import { privateRoutes } from "constant/route/privateRoutes";
import { useLocation, useNavigate } from "react-router-dom";
import { getAllJobs } from "services/jobAPIs";
import { useCommonContext } from "hooks‬/CommonContext";
import { ERROR_STATUS_KEY } from "constant/errorCode";

const MAX_FILE_SIZE = 5 * 1024 * 1024;

const UploadMultiResumeCandidate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showSuccessToast, showErrorToast } = useCommonContext();
  const [docFiles, setDocFiles] = useState<File[]>([]);
  const [errorMessages, setErrorMessages] = useState({
    multiFileError: "",
    excelFileError: "",
  });
  const [selectJob, setSelectJob] = useState<any>({
    label: "",
    value: "",
  });

  const location = useLocation();

  const urlParams = new URLSearchParams(location.search);

  const createOption = urlParams.get("createOption") ?? "";

  const onDocDrop = useCallback((acceptedFiles: File[]) => {
    const filteredFiles = acceptedFiles.filter(
      (file) => file.size <= MAX_FILE_SIZE
    );
    const oversizedFiles = acceptedFiles.filter(
      (file) => file.size > MAX_FILE_SIZE
    );
    if (oversizedFiles.length > 0) {
      setErrorMessages((prev) => ({
        ...prev,
        multiFileError: t("messages:MULTI.FILE.ERROR"),
      }));
      setTimeout(() => {
        setErrorMessages((prev) => ({
          ...prev,
          multiFileError: "",
        }));
      }, 5000);
    }

    if (filteredFiles.length + docFiles.length > 5) {
      setErrorMessages((prev) => ({
        ...prev,
        multiFileError: t("You can only upload a maximum of 5 files"),
      }));
      return;
    }
    setDocFiles((prevFiles) => [...prevFiles, ...filteredFiles]);
    // eslint-disable-next-line
  }, []);

  const { getRootProps: getDocRootProps, getInputProps: getDocInputProps } =
    useDropzone({
      onDrop: onDocDrop,
      accept: {
        "application/pdf": [".pdf"],
        "application/msword": [".doc", ".docx"],
      },
      multiple: createOption === "multiFileUpload",
    });

  const removeDocFile = (index: number) => {
    setDocFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const submitFormHandler = () => {
    const data = new FormData();

    docFiles.forEach((file) => {
      data.append("resumes", file);
    });

    data.append("jobDetailsGuid", selectJob.value);

    uploadResumeToCreateCandidate(data)
      .then((response) => {
        showSuccessToast("CREATED.SUCCESSFULLY");
        console.log(response);
        if (response.data.status === ERROR_STATUS_KEY) {
          showErrorToast(response.data.statusDesc);
        } else {
          showSuccessToast(t("messages:CREATED.SUCCESSFULLY"));
          navigate("/candidates");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const [jobOptions, setJobOptions] = useState<any>([]);
  const fetchAllJobs = async () => {
    try {
      await getAllJobs()
        .then((response: any) => {
          setJobOptions(
            response.data.data.map((item: any) => ({
              value: item.jobDetails.jobDetailsGuid,
              label: item.jobDetails.displayName,
            }))
          );
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchAllJobs();
  }, []);

  return (
    <div className=" row   justify-content-center">
      <div className="col-6">
        <br />

        <SelectInput
          label={t("SELECT.JOB")}
          name="selectedJobCategory"
          selectedOption={selectJob}
          setSelectedOption={(e: any) => {
            setSelectJob(e.target);
          }}
          options={jobOptions}
        />
        <div
          id="doc-upload"
          className="border border-2 py-10 border-dashed radius-12 bg-white d-flex flex-column gap-4 align-items-center justify-content-between"
          {...getDocRootProps()}
        >
          <input {...getDocInputProps()} />
          <img
            src={multiFileUpload}
            className="h-45px"
            alt="multi-file-upload"
          />
          <div className="fs-16">
            {t("messages:DRAG.DROP")}{" "}
            <span className="fw-bold text-decoration-underline cursor-pointer">
              {t("CHOOSE.FILE")}
            </span>
          </div>

          <div className="d-flex flex-column align-items-center justify-content-between text-muted my-2">
            <div className="fs-16">{t("SUPPORTED.FORMAT")} PDF, doc, docx</div>
            <div className="fs-16">{t("MAXIMUM.SIZE")}</div>
          </div>
        </div>
        {errorMessages.multiFileError.length > 0 && (
          <div className="d-flex justify-content-between text-danger my-0 mx-4">
            <span>{errorMessages.multiFileError}</span>
          </div>
        )}
        <div className="fs-16 p-2">Uploaded files</div>

        {docFiles.length > 0 &&
          docFiles.map((file, index) => (
            <UploadedFile
              key={file.name}
              fileName={file.name}
              removeUploadedFile={() => removeDocFile(index)}
            />
          ))}

        <div className="d-flex justify-content-end pt-4 gap-4">
          <div>
            <FilledGrayButton
              onClick={() => navigate(privateRoutes.candidates)}
              label={t("CANCEL")}
            />
          </div>
          <div>
            <FilledButtons
              onClick={() => submitFormHandler()}
              label={t("UPLOAD")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadMultiResumeCandidate;
