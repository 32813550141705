import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { MdOutlineFileDownload } from "react-icons/md";
import excelIcon from "assets/svg/excel-icon.svg";
import { useTranslation } from "react-i18next";
import SelectInput from "components/form/SelectInput";
import FilledGrayButton from "components/button/FilledGrayButton";
import FilledButtons from "components/button/FilledButtons";
import { uploadResumeToCreateCandidate } from "services/candidates";
import { privateRoutes } from "constant/route/privateRoutes";
import { useNavigate } from "react-router-dom";
import UploadedFile from "components/ui-components/UploadedFile";
const options = [
  { value: "option 1", label: "Option 1" },
  { value: "option 2", label: "Option 2" },
  { value: "option 3", label: "Option 3" },
  { value: "option 4", label: "Option 4" },
  { value: "option 5", label: "Option 5" },
];

const MAX_FILE_SIZE = 5 * 1024 * 1024;

const UploadExcelToCandidate = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [excelFiles, setExcelFiles] = useState<File | null>(null);
  const [errorMessages, setErrorMessages] = useState({
    multiFileError: "",
    excelFileError: "",
  });
  const [selectJob, setSelectJob] = useState<any>({
    label: "",
    value: "",
  });

  const onExcelDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    setErrorMessages((prev) => ({
      ...prev,
      excelFileError: "",
    }));
    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        setErrorMessages((prev) => ({
          ...prev,
          excelFileError: t("messages:EXCEL.FILE.ERROR"),
        }));

        setTimeout(() => {
          setErrorMessages((prev) => ({
            ...prev,
            excelFileError: "",
          }));
        }, 5000);
      } else {
        setExcelFiles(file);
      }
    }
    // eslint-disable-next-line
  }, []);

  const { getRootProps: getExcelRootProps, getInputProps: getExcelInputProps } =
    useDropzone({
      onDrop: onExcelDrop,
      accept: {
        "application/vnd.ms-excel": [".xls", ".xlsx"],
      },
      multiple: false,
    });

  const removeExcelFile = () => {
    setExcelFiles(null);
  };

  const downloadExcelFile = () => {
    window.location.href =
      "https://fragilestatesindex.org/wp-content/uploads/2021/05/fsi-2021.xlsx";
  };

  const submitFormHandler = () => {
    const data = new FormData();

    if (excelFiles) data.append("resumes", excelFiles);

    data.append("jobDetailsGuid", selectJob.value);

    uploadResumeToCreateCandidate(data)
      .then((response) => {
        console.log("Response:", JSON.stringify(response.data));
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <div className=" row   justify-content-center">
      <div className="col-6">
        <SelectInput
          label={t("SELECT.JOB")}
          name="selectedJobCategory"
          selectedOption={selectJob}
          setSelectedOption={(e: any) => {
            setSelectJob(e.target);
          }}
          options={options}
        />

        <div
          id="excel-upload"
          className="border border-2 py-10 border-dashed radius-12 bg-white d-flex flex-column gap-4 align-items-center justify-content-between"
          {...getExcelRootProps()}
        >
          <input {...getExcelInputProps()} />
          <img src={excelIcon} className="h-45px" alt="excel-icon" />
          <div className="fs-16">
            {t("messages:DRAG.DROP")}{" "}
            <span className="fw-bold text-decoration-underline cursor-pointer">
              {t("CHOOSE.FILE")}
            </span>
          </div>
          <div className="d-flex flex-column align-items-center justify-content-between text-muted my-2">
            <div className="fs-16">
              {t("SUPPORTED.FORMAT")} {t("EXCEL")}
            </div>
            <div className="fs-16">{t("MAXIMUM.SIZE")}</div>
          </div>
        </div>
        {errorMessages.excelFileError.length > 0 && (
          <div className="d-flex justify-content-between text-danger my-0 mx-4">
            <span>{errorMessages.excelFileError}</span>
          </div>
        )}

        <br />

        <div className="fs-16 my-2">Template file to download</div>
        <button
          className="my-2 d-flex  w-100  justify-content-between  gap-2 align-items-center p-4 radius-12 n-bg-primary-opacity-10 n-border-2-primary-opacity-10"
          onClick={downloadExcelFile}
        >
          <div>
            <img src={excelIcon} className="h-25px" alt="excel-icon" />
            <span className="mx-5 ">{t("EXCEL.NAME")}</span>
          </div>
          <MdOutlineFileDownload style={{ fontSize: "26px" }} />
        </button>
        <br />
        {excelFiles && (
          <>
            <div className="fs-16 my-2">Uploaded files</div>
            <div>
              <UploadedFile
                fileName={excelFiles.name}
                isExcel={true}
                removeUploadedFile={removeExcelFile}
              />
            </div>
          </>
        )}
        <div className="d-flex justify-content-end pt-4 gap-4">
          <div>
            <FilledGrayButton
              onClick={() => navigate(privateRoutes.createCandidates)}
              label={t("CANCEL")}
            />
          </div>
          <div>
            <FilledButtons
              onClick={() => submitFormHandler()}
              label={t("UPLOAD")}
            />
          </div>
        </div>
      </div>{" "}
    </div>
  );
};

export default UploadExcelToCandidate;
