import React, { FC } from "react";

const FullModalTitle: FC<{ title: string }> = ({ title }) => {
  return (
    <div
      className="w-100 text-center fw-bold mb-10"
      style={{ fontSize: "32px" }}
    >
      {title}
    </div>
  );
};

export default FullModalTitle;
