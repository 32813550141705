import {
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableBodyRow,
} from "components/Table";
import CardContainer from "components/cards/CardContainer";
import React, { useEffect } from "react";
import HeaderGrid from "components/cards/card-grid/HeaderGrid";
import FilledButtons from "components/button/FilledButtons";
import ActionMenuBox from "components/menu/action-menu/ActionMenuBox";
import ActionMenuItem from "components/menu/action-menu/ActionMenuItem";
import { useTranslation } from "react-i18next";
import { MdMoreHoriz } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Pagination from "components/base/Pagination";
import useFetch from "hooks‬/useFetch";
import { candidateApiEndpoint } from "constant/APIConstant";
const MenuTrigger = () => {
  const { t } = useTranslation();
  // this function will use when we implement Edit and Delete feature
  const actionHandler = () => {
    console.log("Event");
  };
  return (
    <ActionMenuBox>
      <ActionMenuItem title={t("Edit")} onClickAction={actionHandler} />
      <div className="separator my-0"></div>
      <ActionMenuItem title={t("Delete")} onClickAction={actionHandler} />
    </ActionMenuBox>
  );
};

const Index = () => {
  const navigate = useNavigate();

  const {
    data: candidateList,
    isLoading,
    currentPage,
    totalPages,
    cursors,
    fetchData: fetchAllCandidatesHandler,
  } = useFetch<any>({
    url: candidateApiEndpoint.candidate,
  });

  useEffect(() => {
    if (!isLoading && candidateList.length <= 0) {
      navigate("create");
    }
  }, [isLoading, candidateList.length, navigate]);

  return (
    <CardContainer>
      <HeaderGrid>
        <div>
          <FilledButtons
            onClick={() => navigate("create")}
            label={"Create Candidate"}
            iconName="plus-square"
          />
        </div>
      </HeaderGrid>
      <Table width="500px">
        <TableHead>
          <TableHeadRow>
            <TableHeadCell>Name</TableHeadCell>
            <TableHeadCell>Current Location</TableHeadCell>
            <TableHeadCell>Email</TableHeadCell>
            <TableHeadCell>Mobile Number</TableHeadCell>
            <TableHeadCell>Total Experience</TableHeadCell>
            <TableHeadCell>Resume</TableHeadCell>
            <TableHeadCell>Action</TableHeadCell>
          </TableHeadRow>
        </TableHead>

        <TableBody>
          {candidateList.map((listItem: any) => {
            return (
              <TableBodyRow key={listItem.guid}>
                <TableBodyCell>
                  {listItem.candidateDetails.firstName}{" "}
                  {listItem.candidateDetails.lastName}
                </TableBodyCell>
                <TableBodyCell>
                  {listItem.candidateDetails.address1}
                </TableBodyCell>
                <TableBodyCell>{listItem.candidateDetails.email}</TableBodyCell>
                <TableBodyCell>
                  {listItem.candidateDetails.mobileNumber}
                </TableBodyCell>
                <TableBodyCell>
                  {listItem.candidateDetails.totalExperience}
                </TableBodyCell>
                <TableBodyCell>
                  <div>
                    <FilledButtons
                      onClick={() => console.log("cancel")}
                      label="Resume"
                    />
                  </div>
                </TableBodyCell>
                <TableBodyCell>
                  <div>
                    <div
                      className="text-primary-white rounded-pill cursor-pointer"
                      data-kt-menu-trigger="click"
                      data-kt-menu-placement="bottom-end"
                    >
                      <div className="more-action-btn w-50px text-white p-1 d-flex justify-content-center mx-1    rounded">
                        <MdMoreHoriz style={{ fontSize: "24px" }} />
                      </div>
                    </div>
                    <MenuTrigger />
                  </div>
                </TableBodyCell>
              </TableBodyRow>
            );
          })}
        </TableBody>
      </Table>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={fetchAllCandidatesHandler}
        cursors={cursors}
      />
    </CardContainer>
  );
};

export default Index;
