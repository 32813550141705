import { removeCookie } from "./common";
const ATS_ACCESS_TOKEN_KEY = "ETSXC-AT-PETS";

const getATSAccessToken = (): string => {
  if (!localStorage) {
    return "";
  }
  const token: string | null = localStorage.getItem(ATS_ACCESS_TOKEN_KEY);
  if (!token) {
    return "";
  } else {
    return token;
  }
};

const setATSAccessToken = (token: string) => {
  if (!localStorage) {
    return;
  }
  try {
    localStorage.setItem(ATS_ACCESS_TOKEN_KEY, token);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE SAVE ERROR", error);
  }
};

const removeAuth = () => {
  if (!localStorage) {
    return;
  }
  try {
    localStorage.removeItem(ATS_ACCESS_TOKEN_KEY);
    removeCookie("udo-oi-amdmdx");
  } catch (error) {
    console.error("AUTH LOCAL STORAGE REMOVE ERROR", error);
  }
};

export { removeAuth, getATSAccessToken, setATSAccessToken };
