import {
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableBodyRow,
} from "components/Table";
import MenuOpenButton from "components/button/MenuOpenButton";
import CardContainer from "components/cards/CardContainer";
import React, { useState } from "react";
import HeaderGrid from "components/cards/card-grid/HeaderGrid";
import RightCardHeaderContainer from "components/cards/card-grid/RightCardHeaderContainer";
import FilledButtons from "components/button/FilledButtons";
import { MdMoreHoriz } from "react-icons/md";
import ScheduleInterview from "./ScheduleInterview";
import MenuTrigger from "./MenuTrigger";

const interviewList = [
  {
    jobRole: "React Developer",
    candidate: "XYZ",
    interviewer: "ABCD",
    date: "15 Oct 2024",
    time: "10am-11am",
  },
  {
    jobRole: "Java Developer",
    candidate: "PQR",
    interviewer: "ABCD",
    date: "15 Oct 2024",
    time: "10am-11am",
  },
];

const Index = () => {
  const [modalToggle, setModalToggle] = useState(false);
  return (
    <React.Fragment>
      <CardContainer>
        <HeaderGrid>
          <div>
            <FilledButtons
              onClick={() => setModalToggle(true)}
              label={"Schedule Interview"}
              iconName="plus-square"
            />
          </div>
          <RightCardHeaderContainer>
            <MenuOpenButton label="Filter" iconName="setting-4" />
          </RightCardHeaderContainer>
        </HeaderGrid>
        <Table width="500px">
          <TableHead>
            <TableHeadRow>
              <TableHeadCell>Job Role</TableHeadCell>
              <TableHeadCell>Candidate</TableHeadCell>
              <TableHeadCell>Interviewer</TableHeadCell>
              <TableHeadCell>Date</TableHeadCell>
              <TableHeadCell>Time</TableHeadCell>
              <TableHeadCell>Action</TableHeadCell>
            </TableHeadRow>
          </TableHead>

          <TableBody>
            {interviewList.map((listItem) => {
              return (
                <TableBodyRow key={listItem.jobRole}>
                  <TableBodyCell>{listItem.jobRole}</TableBodyCell>
                  <TableBodyCell>{listItem.candidate}</TableBodyCell>
                  <TableBodyCell>{listItem.interviewer}</TableBodyCell>
                  <TableBodyCell>{listItem.date}</TableBodyCell>
                  <TableBodyCell>{listItem.time}</TableBodyCell>
                  <TableBodyCell>
                    {" "}
                    <div className="w-50px">
                      <div
                        className="text-primary-white rounded-pill cursor-pointer"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        <div className="bg-gray-700 text-white p-1 d-flex justify-content-center mx-1  rounded">
                          <MdMoreHoriz style={{ fontSize: "24px" }} />
                        </div>
                      </div>
                      <MenuTrigger />
                    </div>
                  </TableBodyCell>
                </TableBodyRow>
              );
            })}
          </TableBody>
        </Table>
      </CardContainer>
      {modalToggle && <ScheduleInterview setModalToggle={setModalToggle} />}
    </React.Fragment>
  );
};

export default Index;
