const version = "/v1";
const ats = "/ats";
export const jobApiEndpoint = {
  jobDescription: ats + version + "/job_description/section",
  jobTypes: ats + version + "/job/type",
  jobShift: ats + version + "/job/shift",
  jobCategories: ats + version + "/job/category",
  workModeLocation: ats + version + "/job/work_location",
  companyBranches: ats + version + "/company/branch",
  job: ats + version + "/job",
};

export const candidateApiEndpoint = {
  candidate: ats + version + "/candidate",
  uploadResumes: ats + version + "/candidate/upload_resumes",
};

export const commonApiEndpoint = {
  currency: version + "/currency?limit=200",
  country: version + "/country",
  state: version + "/state",
  city: version + "/city",
};
