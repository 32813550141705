import React from "react";
import { useTranslation } from "react-i18next";

const CreateJobFormTitle = () => {
  const { t } = useTranslation();
  return (
    <div className="text-center fw-bold" style={{ fontSize: "24px" }}>
      {t("CREATE.A.JOB")}
    </div>
  );
};

export default CreateJobFormTitle;
