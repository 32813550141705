import { FC } from "react";
import profilePic from "../../../assets/images/Profile.png";
import { useTranslation } from "react-i18next";
import { useAuth } from "hooks‬/Auth";
import { useAuth0 } from "@auth0/auth0-react";
const HeaderUserMenu: FC = () => {
  const { t } = useTranslation();
  const { systemLogout } = useAuth();
  const { user } = useAuth0();
  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
      data-kt-menu="true"
    >
      <div className="menu-item px-3">
        <div className="menu-content d-flex align-items-center px-3">
          <div className="symbol symbol-50px me-5">
            <img alt="Logo" src={profilePic} />
          </div>
          <div className="d-flex flex-column">
            {user?.name}
            <div style={{ wordBreak: "break-all" }}>
              <p className="text-muted">{user?.email}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="separator my-2"></div>
      <div
        className="menu-item px-5"
        onClick={() => {
          systemLogout();
        }}
      >
        <div className="menu-link px-5">{t("LOGOUT")}</div>
      </div>
    </div>
  );
};

export { HeaderUserMenu };
