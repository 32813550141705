import React, { FC } from "react";
import formDocument from "assets/svg/forms-document.svg";
import fileUpload from "assets/svg/file-upload.svg";
import multiFileUpload from "assets/svg/multi-file-upload.svg";
import excelUpload from "assets/svg/excel-icon.svg";
import CompleteFormToCandidate from "./CompleteFormToCandidate";
import UploadMultiResumeCandidate from "./UploadMultiResumeCandidate";
import { useLocation, useNavigate } from "react-router-dom";
import UploadExcelToCandidate from "./UploadExcelToCandidate";
import EventDivBoxLayout from "layout/EventDivBoxLayout";
const createOptions = [
  "manual",
  "multiFileUpload",
  "fileUpload",
  "excelUpload",
];
interface OptionProps {
  imgSrc: any;
  title: string;
  subTitle: string;
  onClickEvent: () => void;
}
const OptionCard: FC<OptionProps> = ({
  imgSrc,
  title,
  subTitle,
  onClickEvent,
}) => {
  return (
    <EventDivBoxLayout
      onClickEvent={() => {
        onClickEvent();
      }}
      className="n-bg-gray-3 d-flex flex-column justify-content-between fs-20 p-5 radius-12"
    >
      <div className="my-4">
        <img className="h-40px" src={imgSrc} alt="icon" />
      </div>
      <div className="fs-20 fw-bold ">{title}</div>
      <div className="fs-16 w-200px" style={{ marginRight: "15px" }}>
        {subTitle}
      </div>
    </EventDivBoxLayout>
  );
};
const CreateCandidateOptions = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const urlParams = new URLSearchParams(location.search);

  const createOption = urlParams.get("createOption") ?? "";
  console.log("createOption", createOption);
  return (
    <>
      {createOptions.includes(createOption) ? (
        <>
          {createOption === "manual" && <CompleteFormToCandidate />}
          {createOption === "fileUpload" && <UploadMultiResumeCandidate />}
          {createOption === "multiFileUpload" && <UploadMultiResumeCandidate />}
          {createOption === "excelUpload" && <UploadExcelToCandidate />}
        </>
      ) : (
        <div className="d-flex justify-content-center flex-column align-items-center">
          <div className="text-center w-300px mb-10">
            <div className="fs-32">Create Candidate</div>
            <div className="fs-16">
              Create a candidate profile through one of the provided options.
            </div>
          </div>
          <div className="d-flex flex-wrap justify-content-center gap-4">
            <OptionCard
              imgSrc={formDocument}
              title="Complete a form"
              subTitle="Manually enter candidate details"
              onClickEvent={() => navigate("?createOption=manual")}
            />
            <OptionCard
              imgSrc={fileUpload}
              title="Upload a resume"
              subTitle="Upload a single resume"
              onClickEvent={() => navigate("?createOption=fileUpload")}
            />
            <OptionCard
              imgSrc={multiFileUpload}
              title="Upload multiple resume"
              subTitle="Batch upload multiple resumes"
              onClickEvent={() => navigate("?createOption=multiFileUpload")}
            />
            <OptionCard
              imgSrc={excelUpload}
              title="Import excel"
              subTitle="Import candidates from an Excel file"
              onClickEvent={() => navigate("?createOption=excelUpload")}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default CreateCandidateOptions;
