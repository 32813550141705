import { Collapse } from "react-bootstrap";
import { WithChildren } from "helpers";
import React, { FC } from "react";
import { IoIosArrowUp } from "react-icons/io";
import EventDivBoxLayout from "layout/EventDivBoxLayout";

interface Props {
  title: string;
  setCollapseSection: () => void;
  open: boolean;
}
const FormSectionBox: FC<Props & WithChildren> = ({
  title,
  children,
  open,
  setCollapseSection,
}) => {
  return (
    <div className="card radius-12 ">
      <EventDivBoxLayout
        onClickEvent={() => {
          setCollapseSection();
        }}
        className="n-bg-gray-3 d-flex justify-content-between w-100 fs-20 p-5 radius-12"
      >
        {title}
        <IoIosArrowUp className={`arrow ${open ? "rotate" : ""}`} />
      </EventDivBoxLayout>
      <Collapse in={open}>
        <div className="px-4">{children}</div>
      </Collapse>
    </div>
  );
};

export default FormSectionBox;

export const FieldRowGrid: FC<
  WithChildren & { title: string; styleColClass?: string }
> = ({ title, styleColClass = "", children }) => {
  return (
    <div className="row my-5 ">
      <div className="col-3">{title}</div>
      <div className="col-9">
        <div className={"row " + styleColClass}>{children}</div>
      </div>
    </div>
  );
};

export const FieldColGrid: FC<WithChildren> = ({ children }) => {
  return <div className="col-4">{children}</div>;
};

export const FieldSeparator = () => {
  return <div className="separator mt-5 mb-10"></div>;
};
