import React, { FC, useState } from "react";
import {
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableHeadRow,
} from "components/Table";
import TableBodyRow from "components/Table/TableBodyRow";
import { jobListings } from "dummy-json/jobs";
import { Link } from "react-router-dom";
import CardContainer from "components/cards/CardContainer";
import ModalOpenButton from "components/button/ModalOpenButton";
import { useTranslation } from "react-i18next";
import ActionMenuBox from "components/menu/action-menu/ActionMenuBox";
import ActionMenuItem from "components/menu/action-menu/ActionMenuItem";
import ModalContainer from "components/modal/ModalContainer";
import CreateJob from "pages/Jobs/components/CreateJob";
import MenuOpenButton from "components/button/MenuOpenButton";
const MenuTrigger = () => {
  const { t } = useTranslation();

  return (
    <ActionMenuBox>
      <ActionMenuItem
        title={t("Open")}
        onClickAction={() => {
          console.log("open");
        }}
      />
      <ActionMenuItem
        title={t("Close   ")}
        onClickAction={() => {
          console.log("open");
        }}
      />
    </ActionMenuBox>
  );
};
type Props = {
  clientDetails: any;
};
const ClientsJobs: FC<Props> = ({ clientDetails }) => {
  const [modalToggle, setModalToggle] = useState(false);
  return (
    <CardContainer>
      <div className="d-flex justify-content-between">
        <div
          onClick={() => setModalToggle(true)}
          onKeyDown={() => setModalToggle(true)}
          role="button"
          tabIndex={0}
        >
          <ModalOpenButton iconName="plus-square" label={"Create Jobs"} />
        </div>
        <div className="d-flex gap-2">
          <MenuOpenButton label="Filter" iconName="setting-4" />
          <MenuOpenButton label="More" iconName="dots-horizontal" />
        </div>
      </div>
      <div className="w-100 border-bottom border-2 my-4"></div>
      <Table>
        <TableHead>
          <TableHeadRow>
            <TableHeadCell>Position Name</TableHeadCell>
            <TableHeadCell> Start Date</TableHeadCell>
            <TableHeadCell> End Date</TableHeadCell>
            {/* <TableHeadCell>Location</TableHeadCell> */}
            <TableHeadCell>Package Range</TableHeadCell>
            <TableHeadCell>Total Vacancy</TableHeadCell>
            <TableHeadCell>Status</TableHeadCell>
            <TableHeadCell>Created Date</TableHeadCell>
          </TableHeadRow>
        </TableHead>

        <TableBody>
          {jobListings.map((listItem) => {
            return (
              <TableBodyRow key={listItem.guid}>
                <TableBodyCell>
                  <Link to={`/client/${listItem.guid}?tab=jobs`}>
                    {listItem.positionName}
                  </Link>
                </TableBodyCell>
                <TableBodyCell>{listItem.campaignStartDate}</TableBodyCell>
                <TableBodyCell>{listItem.campaignEndDate}</TableBodyCell>
                {/* <TableBodyCell>{listItem.jobLocation}</TableBodyCell> */}
                <TableBodyCell>{listItem.packageRange}</TableBodyCell>
                <TableBodyCell>{listItem.totalVacancy}</TableBodyCell>
                <TableBodyCell>
                  <div>
                    <div
                      className="text-primary-white rounded-pill cursor-pointer"
                      data-kt-menu-trigger="click"
                      data-kt-menu-placement="bottom-end"
                    >
                      {listItem.status}
                    </div>
                    <MenuTrigger />
                  </div>
                </TableBodyCell>
                <TableBodyCell>{listItem.createdDate}</TableBodyCell>
              </TableBodyRow>
            );
          })}
        </TableBody>
      </Table>

      <ModalContainer
        modalToggle={modalToggle}
        setModalToggle={setModalToggle}
        title="Create Jobs"
      >
        {modalToggle && <CreateJob clientDetails={clientDetails} />}
      </ModalContainer>
    </CardContainer>
  );
};

export default ClientsJobs;
