import clsx from "clsx";
import { getCursorValue } from "helpers/common";
import React, { useEffect, useState } from "react";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa6";
const maxVisiblePages = 3;

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  handlePageChange: (page?: number | null, cursor?: string | null) => void;
  cursors: { rel: string; value: string }[];
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  handlePageChange,
  cursors,
}) => {
  const [nextCursor, setNextCursor] = useState<string | null>(null);
  const [previousCursor, setPreviousCursor] = useState<string | null>(null);
  // const [lastCursor, setLastCursor] = useState<string | null>(null);
  // const [firstCursor, setFirstCursor] = useState<string | null>(null);
  const getVisiblePages = () => {
    let start = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let end = Math.min(totalPages, start + maxVisiblePages - 1);
    if (end - start + 1 < maxVisiblePages) {
      start = Math.max(1, end - maxVisiblePages + 1);
    }
    const pages = [];
    for (let i = start; i <= end; i++) {
      pages.push(i);
    }
    return pages;
  };
  useEffect(() => {
    setNextCursor(getCursorValue(cursors, "next"));
    setPreviousCursor(getCursorValue(cursors, "previous"));
    // setFirstCursor(getCursorValue(cursors, "first"));
    // setLastCursor(getCursorValue(cursors, "last"));
  }, [cursors]);

  const iconBtnStyle = {
    background: "none",
    border: "none",
    padding: 0,
    margin: 0,
  };
  if (totalPages === 1) {
    return null;
  }
  return (
    <div
      className="pagination-container"
      style={{
        display: "flex",
        gap: "5px",
        justifyContent: "center",
        padding: "20px",
      }}
    >
      <button
        onClick={() => handlePageChange(null, previousCursor)}
        disabled={currentPage === 1}
        style={iconBtnStyle}
        className="d-flex justify-content-between align-items-center"
      >
        <FaAngleLeft
          className={clsx("fs-1", currentPage !== 1 && "text-primary")}
        />
      </button>

      {getVisiblePages().map((page) => {
        const isCurrentPage = page === currentPage;
        return (
          <button
            key={page}
            onClick={() => handlePageChange(page, null)}
            style={{
              width: "28px",
              height: "28px",
              fontSize: "12px",
              border: "1px solid #ccc",
              color: isCurrentPage ? "white" : "black",
              cursor: "pointer",
            }}
            className={clsx(
              "d-flex justify-content-center align-items-center rounded-circle border-0",
              {
                "bg-primary": isCurrentPage,
              }
            )}
          >
            {page}
          </button>
        );
      })}

      <button
        onClick={() => handlePageChange(null, nextCursor)}
        disabled={currentPage === totalPages}
        className="border-0 d-flex justify-content-between align-items-center"
        style={iconBtnStyle}
      >
        <FaAngleRight
          className={clsx("fs-1", currentPage !== totalPages && "text-primary")}
        />
      </button>
    </div>
  );
};

export default Pagination;
