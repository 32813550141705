import React, { FC } from "react";
import { RxCross1 } from "react-icons/rx";
interface Props {
  setModalToggle: any;
}
const CloseCrossIcons: FC<Props> = ({ setModalToggle }) => {
  return (
    <RxCross1
      onClick={() => setModalToggle(false)}
      className="cursor-pointer"
      style={{ fontSize: "24px" }}
    />
  );
};

export default CloseCrossIcons;
