import {
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableBodyRow,
} from "components/Table";
import MenuOpenButton from "components/button/MenuOpenButton";
import CardContainer from "components/cards/CardContainer";
import React, { useEffect, useState } from "react";
import HeaderGrid from "components/cards/card-grid/HeaderGrid";
import RightCardHeaderContainer from "components/cards/card-grid/RightCardHeaderContainer";
import CreateJobStepperForm from "./CreateJobStepperForm";
import FilledButtons from "components/button/FilledButtons";
import ActionMenuBox from "components/menu/action-menu/ActionMenuBox";
import ActionMenuItem from "components/menu/action-menu/ActionMenuItem";
import { useTranslation } from "react-i18next";
import { MdMoreHoriz } from "react-icons/md";
import DataNotFound from "components/base/DataNotFound";
import useFetch from "hooks‬/useFetch";
import { jobApiEndpoint } from "constant/APIConstant";
import Pagination from "components/base/Pagination";

const MenuTrigger = () => {
  const { t } = useTranslation();
  return (
    <ActionMenuBox>
      <ActionMenuItem
        title={t("Edit")}
        onClickAction={() => {
          console.log("open");
        }}
      />
      <div className="separator my-0"></div>
      <ActionMenuItem
        title={t("Delete")}
        onClickAction={() => {
          console.log("open");
        }}
      />
    </ActionMenuBox>
  );
};

const Index = () => {
  const [modalToggle, setModalToggle] = useState(false);
  const { t } = useTranslation();
  const {
    data: jobList,
    isLoading,
    currentPage,
    totalPages,
    cursors,
    fetchData: fetchAllCandidatesHandler,
  } = useFetch<any>({
    url: jobApiEndpoint.job,
  });

  useEffect(() => {
    if (modalToggle) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [modalToggle]);

  return (
    <React.Fragment>
      <CardContainer>
        <HeaderGrid>
          <div>
            <FilledButtons
              onClick={() => setModalToggle(true)}
              label={t("CREATE.JOB")}
              iconName="plus-square"
            />
          </div>
          <RightCardHeaderContainer>
            <MenuOpenButton label="Filter" iconName="setting-4" />
          </RightCardHeaderContainer>
        </HeaderGrid>
        {jobList.length > 0 || isLoading ? (
          <div>
            <Table>
              <TableHead>
                <TableHeadRow>
                  <TableHeadCell>Job Name</TableHeadCell>
                  <TableHeadCell>Total Vacancy</TableHeadCell>
                  <TableHeadCell>CTC</TableHeadCell>
                  <TableHeadCell>Campaign Start Date</TableHeadCell>
                  <TableHeadCell>Campaign EndDate</TableHeadCell>
                  <TableHeadCell>Action</TableHeadCell>
                </TableHeadRow>
              </TableHead>
              <TableBody>
                {jobList.map((listItem: any) => {
                  return (
                    <TableBodyRow key={listItem.jobDetails.jobDetailsGuid}>
                      <TableBodyCell>
                        {listItem.jobDetails.displayName}
                      </TableBodyCell>
                      <TableBodyCell>
                        {listItem.jobDetails.totalVacancy}
                      </TableBodyCell>
                      <TableBodyCell>{listItem.jobDetails.ctc}</TableBodyCell>

                      <TableBodyCell>
                        {listItem.jobDetails.campaignStartDate.slice(0, 10)}{" "}
                        {listItem.jobDetails.campaignStartDate.slice(11, 16)}
                      </TableBodyCell>

                      <TableBodyCell>
                        {listItem.jobDetails.campaignEndDate.slice(0, 10)}{" "}
                        {listItem.jobDetails.campaignEndDate.slice(11, 16)}
                      </TableBodyCell>

                      <TableBodyCell>
                        <div>
                          <div
                            className="text-primary-white rounded-pill cursor-pointer"
                            data-kt-menu-trigger="click"
                            data-kt-menu-placement="bottom-end"
                          >
                            <div className="more-action-btn w-50px text-white p-1 d-flex justify-content-center mx-1  rounded">
                              <MdMoreHoriz style={{ fontSize: "24px" }} />
                            </div>
                          </div>
                          <MenuTrigger />
                        </div>
                      </TableBodyCell>
                    </TableBodyRow>
                  );
                })}
              </TableBody>
            </Table>
            <Pagination
              cursors={cursors}
              currentPage={currentPage}
              totalPages={totalPages}
              handlePageChange={fetchAllCandidatesHandler}
            />
          </div>
        ) : (
          <DataNotFound subTitle="No jobs have been created yet, Click on Create Job button to create new job" />
        )}
      </CardContainer>
      {modalToggle && <CreateJobStepperForm setModalToggle={setModalToggle} />}
    </React.Fragment>
  );
};

export default Index;
