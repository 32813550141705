interface JSONData {
  [key: string]: any;
}

export const areJSONsEqual = (json1: JSONData, json2: JSONData): boolean => {
  const keys1 = Object.keys(json1);
  const keys2 = Object.keys(json2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (json1[key] !== json2[key]) {
      return false;
    }
  }
  return true;
};

export const selectOptionConvertor = (options: any) => {
  const list = options.map((item: any) => ({
    value: item.guid,
    label: item.displayName,
  }));
  return list;
};

export const setCookie = (
  cname: string,
  cvalue: string,
  exdays?: number,
  domain?: string,
  secure?: boolean,
  sameSite?: "None" | "Lax" | "Strict"
): void => {
  domain = domain ?? "";
  secure = secure ?? true;
  sameSite = sameSite ?? "None";
  exdays = exdays ?? 365;

  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;

  const secureFlag = secure ? "secure" : "";
  const sameSiteFlag = `SameSite=${sameSite}`;

  document.cookie = `${cname}=${cvalue};${expires};path=/;${sameSiteFlag};${secureFlag};domain=${domain}`;
};

export const removeCookie = (cookieName: string, domain?: string): void => {
  const d = new Date();
  d.setTime(d.getTime() - 1);
  const expires = `expires=${d.toUTCString()}`;

  domain = domain ?? "";

  document.cookie = `${cookieName}=;${expires};path=/;domain=${domain};`;
};

export const getCursorValue = (
  cursors: { rel: string; value: string }[],
  rel: string
) => {
  return cursors.find((cursor) => cursor.rel === rel)?.value ?? null;
};
