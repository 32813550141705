import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  fallbackLng: "en",
  lng: "en",
  resources: {
    en: {
      messages: require("./locales/english/messages.json"),
      labels: require("./locales/english/labels.json"),
    },
  },
  ns: ["labels", "messages"],
  defaultNS: ["labels"],
});
i18n.languages = ["en"];

export default i18n;
