import React from "react";
import { IoMdAdd } from "react-icons/io";

interface AddInputStringProps {
  inputString: string;
  setInputString: (e: any) => void;
  addString: (skill: string) => void;
}

const AddInputString: React.FC<AddInputStringProps> = ({
  inputString,
  setInputString,
  addString,
}) => {
  return (
    <div className="d-flex mx-4">
      <input
        type="text"
        className="input-skill py-2 px-1 w-100px"
        placeholder="Enter Skill"
        aria-label="Recipient's username"
        aria-describedby="basic-addon2"
        value={inputString}
        onChange={(e: any) => setInputString(e)}
      />
      <button
        className="input-group-text radius-12 n-gray-filled-btn m-1 p-2"
        id="basic-addon2"
        onClick={() => addString(inputString)}
      >
        <IoMdAdd style={{ color: "#000" }} />
      </button>
    </div>
  );
};

export default AddInputString;
