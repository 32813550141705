import { useEffect } from "react";
import { useLocation } from "react-router";
import clsx from "clsx";
import { useLayout } from "../../layout/core";
import { DrawerComponent } from "../../assets/ts/components";
import { WithChildren } from "../../helpers";

const Content = ({ children }: WithChildren) => {
  const { config, classes } = useLayout();
  const location = useLocation();
  useEffect(() => {
    DrawerComponent.hideAll();
  }, [location]);

  const appContentContainer = config.app?.content?.container;
  return (
    <div
      className={clsx(
        "app-content flex-column-fluid",
        classes.content.join(" "),
        config?.app?.content?.class
      )}
    >
      {appContentContainer ? <div>{children}</div> : <>{children}</>}
    </div>
  );
};

export { Content };
