import FilledGrayButton from "components/button/FilledGrayButton";
import React, { FC } from "react";
type Props = {
  label: string;
  actionFunction: () => void;
};
const AddMoreSection: FC<Props> = ({ label, actionFunction }) => {
  return (
    <div className="row gap-0 p-4 ">
      <div className=" col-4 col-lg-5 align-self-center border-primary border-bottom p-0 align-items-center"></div>
      <div className=" col-4 c col-lg-2 p-0">
        <FilledGrayButton
          styleClass=" rounded-pill border border-primary"
          label={label}
          onClick={actionFunction}
        />
      </div>
      <div className="col-4 col-lg-5 align-self-center border-primary border-bottom p-0 align-items-center"></div>
    </div>
  );
};

export default AddMoreSection;
