import { WithChildren } from "helpers";
import React, { FC } from "react";

const HeaderGrid: FC<WithChildren> = ({ children }) => {
  return (
    <div className="d-flex justify-content-between table-header-border  py-4 mb-4">
      {children}
    </div>
  );
};

export default HeaderGrid;
