import React, { FC } from "react";

const LabelBoxWithCross: FC<{ label: string; removeLabel: () => void }> = ({
  label,
  removeLabel,
}) => {
  return (
    <div className="p-1 d-flex gap-4 bg-gray-300 radius-4 mb-1 mx-2">
      <span className="px-1">{label}</span>
      <button className="border-0 radius-4 " onClick={removeLabel}>
        X
      </button>
    </div>
  );
};

export default LabelBoxWithCross;
