import { commonApiEndpoint } from "constant/APIConstant";
import apiInterceptor from "./apiInterceptor";
import { AxiosResponse } from "axios";
export interface Option {
  name: string;
  guid: string;
  displayName: string;
}
export interface SelectionOptionResponse {
  data: Option[];
}
export function getCurrencies() {
  return apiInterceptor.get(commonApiEndpoint.currency);
}

export async function getCountries(): Promise<AxiosResponse> {
  const response: AxiosResponse<SelectionOptionResponse> =
    await apiInterceptor.get<SelectionOptionResponse>(
      `${commonApiEndpoint.country}?limit=300`
    );
  return response;
}

export async function getStates(
  guid: string | undefined
): Promise<AxiosResponse> {
  const response: AxiosResponse<SelectionOptionResponse> =
    await apiInterceptor.get<SelectionOptionResponse>(
      `${commonApiEndpoint.state}?limit=250&country_guid=${guid}`
    );
  return response;
}

export async function getCity(
  guid: string | undefined
): Promise<AxiosResponse> {
  const response: AxiosResponse<SelectionOptionResponse> =
    await apiInterceptor.get<SelectionOptionResponse>(
      `${commonApiEndpoint.city}?limit=3000&state_guid=${guid}`
    );
  return response;
}
