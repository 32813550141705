import {
  FC,
  createContext,
  useContext,
  ReactNode,
  useMemo,
  useEffect,
} from "react";

import * as authHelper from "../helpers/AuthHelpers";
import { useAuth0 } from "@auth0/auth0-react";
import { setCookie } from "helpers/common";

type WithChildren = {
  children?: ReactNode;
};
type AuthContextProps = {
  systemLogout: () => void;
};
const initAuthContextPropsState = {
  systemLogout: () => {},
};

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState);

const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const { logout } = useAuth0();

  const systemLogout = () => {
    authHelper.removeAuth();

    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  const contextValue = useMemo(
    () => ({
      systemLogout,
    }),
    // eslint-disable-next-line
    []
  );
  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

const AuthInit: FC<WithChildren> = ({ children }) => {
  const {
    isAuthenticated,
    getIdTokenClaims,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0();
  useEffect(() => {
    (async function () {
      if (isAuthenticated) {
        try {
          const claims = await getIdTokenClaims();
          const accessToken = await getAccessTokenSilently();
          authHelper.setATSAccessToken(accessToken);
          const cvalue: string = claims?.["org_id"] ?? "";
          setCookie("udo-oi-amdmdx", cvalue);
        } catch (error: any) {
          loginWithRedirect();
        }
      }
    })();
  }, [
    isAuthenticated,
    getIdTokenClaims,
    loginWithRedirect,
    getAccessTokenSilently,
  ]);

  return <>{children}</>;
};

export { AuthProvider, AuthInit, useAuth };
