import React, { FC } from "react";
import notFound from "assets/svg/not-found.svg";
const DataNotFound: FC<{ subTitle?: string }> = ({ subTitle = "" }) => {
  return (
    <div className="d-flex flex-column  p-5 align-items-center justify-content-center">
      <img className="h-250px" src={notFound} alt="not-found-data" />
      <div className="fs-20 ">Data Not found</div>
      <div className="text-muted fs-16">{subTitle}</div>
    </div>
  );
};

export default DataNotFound;
