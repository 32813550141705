import InputTextField from "components/form/InputTextField";
import FormSectionBox, {
  FieldColGrid,
  FieldRowGrid,
  FieldSeparator,
} from "components/ui-components/FormSectionBox";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import pdfIcon from "assets/svg/pdf-icon.svg";
import { useFormik } from "formik";
import { getCity, getCountries, getStates } from "services/commonAPIs";
import SelectInput from "components/form/SelectInput";
import FilledGrayButton from "components/button/FilledGrayButton";
import { RiDeleteBin6Fill } from "react-icons/ri";
import FilledButtons from "components/button/FilledButtons";
import { genderOption } from "constant/json/common";
import LabelBoxWithCross from "components/ui-components/LabelBoxWithCross";
import AddInputString from "components/ui-components/AddInputString";
import UploadedFile from "components/ui-components/UploadedFile";
import AddMoreSection from "components/ui-components/AddMoreSection";
import { privateRoutes } from "constant/route/privateRoutes";
import { useNavigate } from "react-router-dom";

const MAX_FILE_SIZE = 5 * 1024 * 1024;

const initialValues: any = {
  firstName: "",
  lastName: "",
  email: "",
  mobileNumber: "",
  birthDate: "",
  address1: "",
  address2: "",
  skills: [],
  country: null,
  gender: null,

  candidateEducations: [
    {
      instituteName: "",
      degreeName: "",
      fieldOfStudy: "",
      grade: "",
      passoutYear: "",
      passoutMonth: "",
    },
  ],
  candidateWorkExperiences: [
    {
      companyName: "",
      companyDescription: "",
      city: null,
      country: null,
      designation: "",
      task: "",
      skills: [],
      fromDate: "",
      toDate: "",
    },
  ],
};
const CompleteFormToCandidate = () => {
  type SelectOption = {
    value: string;
    label: string;
  };
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [country, setCountry] = useState<SelectOption[]>([]);
  const [states, setStates] = useState<SelectOption[]>([]);
  const [cities, setCities] = useState<SelectOption[]>([]);
  const [workStates, setWorkStates] = useState<SelectOption[][]>([[]]);
  const [workCities, setWorkCities] = useState<SelectOption[][]>([[]]);
  const [collapseSection, setCollapseSection] = useState(0);
  const [docFiles, setDocFiles] = useState<File | null>(null);
  const [dobInputType, setDobInputType] = useState("text");
  const [errorMessages, setErrorMessages] = useState({
    docFileError: "",
  });
  const [inputSkill, setInputSkill] = useState("");
  const [skillInput, setSkillInput] = useState([""]);
  const handleFocus = () => {
    setDobInputType("date");
  };

  const handleBlur = () => {
    setDobInputType("text");
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      console.log(" values");
    },
  });

  const removeUploadedFile = () => {
    setDocFiles(null);
  };

  const onDocDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    setErrorMessages((prev) => ({
      ...prev,
      docFileError: "",
    }));
    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        setErrorMessages((prev) => ({
          ...prev,
          docFileError: t("messages:EXCEL.FILE.ERROR"),
        }));

        setTimeout(() => {
          setErrorMessages((prev) => ({
            ...prev,
            docFileError: "",
          }));
        }, 5000);
      } else {
        setDocFiles(file);
      }
    }
    // eslint-disable-next-line
  }, []);

  const { getRootProps: getDocRootProps, getInputProps: getDocInputProps } =
    useDropzone({
      onDrop: onDocDrop,
      accept: {
        "application/vnd.ms-excel": [".pdf", ".pdf"],
      },
      multiple: false,
    });

  const fetchCountries = async () => {
    try {
      await getCountries()
        .then((res: any) => {
          setCountry(
            res.data.data.map((items: any) => ({
              label: items.displayName,
              value: items.guid,
            }))
          );
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const stateHandler = async (guid: string) => {
    formik.setFieldValue("stateGuid", "");
    formik.setFieldValue("cityGuid", "");
    setStates([]);
    setCities([]);
    try {
      await getStates(guid)
        .then((res: any) => {
          setStates(
            res.data.data.map((items: any) => ({
              label: items.displayName,
              value: items.guid,
            }))
          );
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const cityHandler = async (guid: string) => {
    setCities([]);
    try {
      await getCity(guid)
        .then((res: any) => {
          setCities(
            res.data.data.map((items: any) => ({
              label: items.displayName,
              value: items.guid,
            }))
          );
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  useEffect(() => {
    if (formik.values.country?.value) stateHandler(formik.values.country.value);
    // eslint-disable-next-line
  }, [formik.values.country]);

  useEffect(() => {
    if (formik.values.state?.value) cityHandler(formik.values.state.value);
    // eslint-disable-next-line
  }, [formik.values.state]);

  const onChangeSelectedField = (target: any) => {
    formik.setFieldValue(target.target.name, {
      value: target.target.value,
      label: target.target.label,
    });
  };

  const addSkill = (value: any) => {
    const skills = [...formik.values.skills, value];
    formik.setFieldValue("skills", skills);
    setInputSkill("");
  };

  const removeSkill = (value: string) => {
    const skills = formik.values.skills.filter(
      (item: string) => item !== value
    );
    formik.setFieldValue("skills", skills);
  };

  const addSkillForWorkExperience = (index: any, skill: any) => {
    console.log(skill, index);
    if (
      skill &&
      !formik.values.candidateWorkExperiences[index].skills.includes(skill)
    ) {
      const updatedSkills = [
        ...formik.values.candidateWorkExperiences[index].skills,
        skill,
      ];
      formik.setFieldValue(
        `candidateWorkExperiences.${index}.skills`,
        updatedSkills
      );
    }
  };

  const removeSkillFromWorkExperience = (index: any, skillIndex: any) => {
    const updatedSkills = formik.values.candidateWorkExperiences[
      index
    ].skills.filter((_: any, i: any) => i !== skillIndex);
    formik.setFieldValue(
      `candidateWorkExperiences.${index}.skills`,
      updatedSkills
    );
  };

  const addEducationField = () => {
    formik.setFieldValue("candidateEducations", [
      ...formik.values.candidateEducations,
      {
        instituteName: "",
        degree: "",
        fieldOfStudy: "",
        grade: "",
        passoutYear: "",
        passooutMonth: "",
      },
    ]);
  };

  const removeEducationField = (index: number) => {
    const updatedFields = formik.values.candidateEducations.filter(
      (_: any, i: any) => i !== index
    );
    formik.setFieldValue("candidateEducations", updatedFields);
  };

  const addWorkExperience = () => {
    formik.setFieldValue("candidateWorkExperiences", [
      ...formik.values.candidateWorkExperiences,
      {
        companyName: "",
        companyDescription: "",
        country: "",
        state: "",
        city: "",
        designation: "",
        task: "",
        skills: [],
        fromDate: "",
        toDate: "",
      },
    ]);
    setSkillInput([...skillInput, ""]);
  };

  const removeWorkExperience = (index: number) => {
    const updatedExperiences = formik.values.candidateWorkExperiences.filter(
      (_: any, i: number) => i !== index
    );

    formik.setFieldValue("candidateWorkExperiences", updatedExperiences);
    const newSkillInputs = skillInput.filter((_, i) => i !== index);
    setSkillInput(newSkillInputs);
  };

  const handleCountryChange = async (index: number, selectedCountry: any) => {
    formik.setFieldValue(
      `candidateWorkExperiences.${index}.country`,
      selectedCountry
    );
    await getStates(selectedCountry.value)
      .then((res: any) => {
        const fetchedStates = res.data.data.map((items: any) => ({
          label: items.displayName,
          value: items.guid,
        }));

        setWorkStates((prev) => {
          const newStates = [...prev];
          newStates[index] = fetchedStates;
          return newStates;
        });
      })
      .catch((error) => {
        console.log(error);
      });

    formik.setFieldValue(`candidateWorkExperiences.${index}.state`, []);
    formik.setFieldValue(`candidateWorkExperiences.${index}.city`, []);
  };

  const handleStateChange = async (index: number, selectedState: any) => {
    formik.setFieldValue(
      `candidateWorkExperiences.${index}.state`,
      selectedState
    );
    await getCity(selectedState.value)
      .then((res: any) => {
        const fetchedStates = res.data.data.map((items: any) => ({
          label: items.displayName,
          value: items.guid,
        }));
        setWorkCities((prev) => {
          const newStates = [...prev];
          newStates[index] = fetchedStates;
          return newStates;
        });
      })
      .catch((error) => {
        console.log(error);
      });

    formik.setFieldValue(`candidateWorkExperiences.${index}.city`, []);
  };

  return (
    <div>
      <FormSectionBox
        title="Basic details"
        setCollapseSection={() => setCollapseSection(1)}
        open={1 === collapseSection}
      >
        <FieldRowGrid title="Name">
          <FieldColGrid>
            <InputTextField
              name="firstName"
              placeholder="First Name"
              onChange={formik.handleChange}
              value={formik.values.firstName}
            />
          </FieldColGrid>
          <FieldColGrid>
            <InputTextField
              placeholder="Last Name"
              name="lastName"
              onChange={formik.handleChange}
              value={formik.values.lastName}
            />
          </FieldColGrid>
        </FieldRowGrid>

        <FieldSeparator />

        <FieldRowGrid title="Contact">
          <FieldColGrid>
            <InputTextField
              placeholder="Mobile Number"
              name="mobileNumber"
              onChange={formik.handleChange}
              value={formik.values.mobileNumber}
            />
          </FieldColGrid>
          <FieldColGrid>
            <InputTextField
              placeholder="Email Id"
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
            />
          </FieldColGrid>
        </FieldRowGrid>

        <FieldSeparator />

        <FieldRowGrid title="Address">
          <FieldColGrid>
            <InputTextField
              placeholder="Address 1"
              name="address1"
              onChange={formik.handleChange}
              value={formik.values.address1}
            />
          </FieldColGrid>
          <FieldColGrid>
            <InputTextField
              placeholder="Address 2"
              name="address2"
              onChange={formik.handleChange}
              value={formik.values.address2}
            />
          </FieldColGrid>
          <FieldColGrid>
            <SelectInput
              name="country"
              placeholder="Country"
              labelClass="required"
              selectedOption={formik.values.country}
              setSelectedOption={onChangeSelectedField}
              options={country}
            />
          </FieldColGrid>
          <FieldColGrid>
            <SelectInput
              name="state"
              placeholder="State"
              labelClass="required"
              selectedOption={formik.values.state}
              setSelectedOption={onChangeSelectedField}
              options={states}
            />
          </FieldColGrid>
          <FieldColGrid>
            <SelectInput
              name="city"
              placeholder="City"
              labelClass="required"
              selectedOption={formik.values.city}
              setSelectedOption={onChangeSelectedField}
              options={cities}
            />
          </FieldColGrid>
        </FieldRowGrid>

        <FieldSeparator />

        <FieldRowGrid title="Skill">
          <div className="col-9">
            <div className="row">
              <div className="col-12 d-flex">
                <div className="h-100 d-flex justify-content-start  align-items-end">
                  {formik.values.skills.map((item: string) => (
                    <LabelBoxWithCross
                      key={"skill" + item}
                      label={item}
                      removeLabel={() => removeSkill(item)}
                    />
                  ))}
                  <AddInputString
                    inputString={inputSkill}
                    setInputString={(e) => setInputSkill(e.target.value)}
                    addString={() => addSkill(inputSkill)}
                  />
                </div>
              </div>
            </div>
          </div>
        </FieldRowGrid>

        <FieldSeparator />

        <FieldRowGrid title="Social Handle">
          <FieldColGrid>
            <InputTextField placeholder="LinkedIn" />
          </FieldColGrid>
          <FieldColGrid>
            <InputTextField placeholder="GitHub" />
          </FieldColGrid>
        </FieldRowGrid>

        <FieldSeparator />

        <FieldRowGrid title="More">
          <FieldColGrid>
            <InputTextField
              type={dobInputType}
              onFocus={handleFocus}
              onBlur={handleBlur}
              placeholder="Birth date"
            />
          </FieldColGrid>
          <FieldColGrid>
            <SelectInput
              name="gender"
              placeholder="Gender"
              selectedOption={formik.values.gender}
              setSelectedOption={onChangeSelectedField}
              options={genderOption}
            />
          </FieldColGrid>
          <FieldColGrid>
            <InputTextField placeholder="Total experience" />
          </FieldColGrid>
        </FieldRowGrid>
      </FormSectionBox>
      <br />

      <FormSectionBox
        title="Education details"
        setCollapseSection={() => setCollapseSection(2)}
        open={2 === collapseSection}
      >
        {formik.values.candidateEducations.map((item: any, index: number) => (
          <div
            key={"candidateEducations" + item}
            className="border border-gray-300 p-5 my-5 radius-12"
          >
            <FieldRowGrid
              styleColClass="justify-content-between"
              title="Institute name"
            >
              <FieldColGrid>
                <InputTextField
                  placeholder="Institute name"
                  name={`candidateEducations.${index}.instituteName`}
                  onChange={formik.handleChange}
                  value={formik.values.candidateEducations[index].instituteName}
                />
              </FieldColGrid>
              <div className="col-2 px-4 d-flex justify-content-end  ">
                <button
                  className="btn btn-icon btn-light-danger border border-danger"
                  onClick={() => removeEducationField(index)}
                >
                  <RiDeleteBin6Fill className="socicon-instagram fs-20" />
                </button>
              </div>
            </FieldRowGrid>

            <FieldSeparator />

            <FieldRowGrid title="Degree details">
              <FieldColGrid>
                <InputTextField
                  placeholder="Degree"
                  name={`candidateEducations.${index}.degree`}
                  onChange={formik.handleChange}
                  value={formik.values.candidateEducations[index].degree}
                />
              </FieldColGrid>
              <FieldColGrid>
                <InputTextField
                  placeholder="Field of study"
                  name={`candidateEducations.${index}.fieldOfStudy`}
                  onChange={formik.handleChange}
                  value={formik.values.candidateEducations[index].fieldOfStudy}
                />
              </FieldColGrid>
              <FieldColGrid>
                <InputTextField
                  placeholder="Grade"
                  name={`candidateEducations.${index}.grade`}
                  onChange={formik.handleChange}
                  value={formik.values.candidateEducations[index].grade}
                />
              </FieldColGrid>
            </FieldRowGrid>

            <FieldSeparator />

            <FieldRowGrid title="Pass out details">
              <FieldColGrid>
                <InputTextField
                  placeholder="Pass out year"
                  name={`candidateEducations.${index}.passOutYear`}
                  onChange={formik.handleChange}
                  value={formik.values.candidateEducations[index].passoutYear}
                />
              </FieldColGrid>
              <FieldColGrid>
                <InputTextField
                  placeholder="Pass out month"
                  name={`candidateEducations.${index}.passOutMonth`}
                  onChange={formik.handleChange}
                  value={formik.values.candidateEducations[index].passoutMonth}
                />
              </FieldColGrid>
            </FieldRowGrid>
          </div>
        ))}
        <AddMoreSection
          label="+ Add more education"
          actionFunction={addEducationField}
        />
      </FormSectionBox>
      <br />
      <FormSectionBox
        title="Work details"
        setCollapseSection={() => setCollapseSection(3)}
        open={3 === collapseSection}
      >
        {formik.values.candidateWorkExperiences.map(
          (experience: any, index: any) => (
            <div
              key={"id" + experience.companyName}
              className="border border-gray-300 p-5 my-5 radius-12"
            >
              <FieldRowGrid title="Company details">
                <FieldColGrid>
                  <InputTextField
                    placeholder="Company name"
                    name={`candidateWorkExperiences.${index}.companyName`}
                    onChange={formik.handleChange}
                    value={experience.companyName}
                  />
                </FieldColGrid>

                <FieldColGrid>
                  <InputTextField
                    placeholder="Company description"
                    name={`candidateWorkExperiences.${index}.companyDescription`}
                    onChange={formik.handleChange}
                    value={experience.companyDescription}
                  />
                </FieldColGrid>

                <div className="col-4 px-4 d-flex justify-content-end  ">
                  <button
                    className="btn btn-icon btn-light-danger border border-danger"
                    onClick={() => removeWorkExperience(index)}
                  >
                    <RiDeleteBin6Fill className="socicon-instagram fs-20" />
                  </button>
                </div>
              </FieldRowGrid>

              <FieldSeparator />

              <FieldRowGrid title="Location">
                <FieldColGrid>
                  <SelectInput
                    name={`candidateWorkExperiences[${index}].country`}
                    placeholder="Country"
                    labelClass="required"
                    selectedOption={
                      formik.values.candidateWorkExperiences[index].country
                    }
                    setSelectedOption={(e: any) => {
                      handleCountryChange(index, e.target);
                    }}
                    options={country}
                  />
                </FieldColGrid>

                <FieldColGrid>
                  <SelectInput
                    name={`candidateWorkExperiences[${index}].state`}
                    placeholder="State"
                    labelClass="required"
                    selectedOption={
                      formik.values.candidateWorkExperiences[index].state
                    }
                    setSelectedOption={(e: any) => {
                      handleStateChange(index, e.target);
                    }}
                    options={workStates[index]}
                  />
                </FieldColGrid>

                <FieldColGrid>
                  <SelectInput
                    name={`candidateWorkExperiences[${index}].city`}
                    placeholder="City"
                    labelClass="required"
                    selectedOption={
                      formik.values.candidateWorkExperiences[index].city
                    }
                    setSelectedOption={(e: any) => {
                      formik.setFieldValue(
                        `candidateWorkExperiences.${index}.city`,
                        e.target
                      );
                    }}
                    options={workCities[index]}
                  />
                </FieldColGrid>
              </FieldRowGrid>

              <FieldSeparator />

              <FieldRowGrid title="Designation">
                <FieldColGrid>
                  <InputTextField
                    placeholder="Designation"
                    name={`candidateWorkExperiences.${index}.designation`}
                    onChange={formik.handleChange}
                    value={experience.designation}
                  />
                </FieldColGrid>
                <FieldColGrid>
                  <InputTextField
                    placeholder="Tasks"
                    name={`candidateWorkExperiences.${index}.task`}
                    onChange={formik.handleChange}
                    value={experience.task}
                  />
                </FieldColGrid>
              </FieldRowGrid>

              <FieldSeparator />

              <FieldRowGrid title="Skill">
                <div className="col-9">
                  <div className="row">
                    <div className="col-12 d-flex">
                      <div className="h-100 d-flex justify-content-start  align-items-end">
                        {experience.skills.map(
                          (skill: any, skillIndex: any) => (
                            <LabelBoxWithCross
                              key={"skill" + skill}
                              label={skill}
                              removeLabel={() =>
                                removeSkillFromWorkExperience(index, skillIndex)
                              }
                            />
                          )
                        )}
                        <AddInputString
                          inputString={skillInput[index]}
                          setInputString={(e) => {
                            const newSkillInputs = [...skillInput];
                            newSkillInputs[index] = e.target.value;
                            setSkillInput(newSkillInputs);
                          }}
                          addString={() => {
                            addSkillForWorkExperience(index, skillInput[index]);
                            const newSkillInputs = [...skillInput];
                            newSkillInputs[index] = "";
                            setSkillInput(newSkillInputs);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </FieldRowGrid>
            </div>
          )
        )}

        <AddMoreSection
          label="+ Add more experience"
          actionFunction={addWorkExperience}
        />
      </FormSectionBox>
      <br />
      <FormSectionBox
        title="Resume"
        setCollapseSection={() => setCollapseSection(4)}
        open={4 === collapseSection}
      >
        <FieldRowGrid title="Upload resume">
          <div className="d-flex gap-4">
            <div
              id="excel-upload"
              className="border border-2 p-3 border-dashed radius-12 bg-gray-100 d-flex align-items-center justify-content-between"
              {...getDocRootProps()}
            >
              <input {...getDocInputProps()} />
              <img src={pdfIcon} className="h-45px" alt="excel-icon" />
              <div className="fs-16">
                {t("messages:DRAG.DROP")}{" "}
                <span className="fw-bold text-decoration-underline cursor-pointer">
                  {t("CHOOSE.FILE")}
                </span>
              </div>
            </div>
            {errorMessages.docFileError.length > 0 && (
              <div className="d-flex justify-content-between text-danger my-0 mx-4">
                <span>{errorMessages.docFileError}</span>
              </div>
            )}

            <div className=" text-muted my-2">
              <div className="fs-16">
                {t("SUPPORTED.FORMAT")} PDF, doc, docx
              </div>
              <div className="fs-16">{t("MAXIMUM.SIZE")}</div>
            </div>
          </div>

          <div>
            {docFiles && (
              <UploadedFile
                fileName={docFiles.name}
                removeUploadedFile={removeUploadedFile}
              />
            )}
          </div>
        </FieldRowGrid>
      </FormSectionBox>
      <div className="d-flex justify-content-end m-4 gap-4">
        <div>
          <FilledGrayButton
            label="Cancel"
            onClick={() => navigate(privateRoutes.createCandidates)}
          />
        </div>
        <div>
          <FilledButtons label="Submit" onClick={formik.submitForm} />
        </div>
      </div>
      <br />
    </div>
  );
};

export default CompleteFormToCandidate;
