import { WithChildren } from "helpers";
import React from "react";

const Index = ({ children }: WithChildren) => {
  return (
    <div className="d-flex justify-content-start align-content-center mb-5">
      {children}
    </div>
  );
};

export default Index;
