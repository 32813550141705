import { WithChildren } from "helpers";
import React, { FC } from "react";

export const CreateJobFormContainer: FC<WithChildren> = ({ children }) => {
  return (
    <div className=" w-100 h-100 card border-0 job-stepper-form-container">
      <div className="d-flex flex-column flex-lg-row flex-column-fluid h-100">
        {children}
      </div>
    </div>
  );
};

export const CreateJobFormFieldLayout: FC<WithChildren> = ({ children }) => {
  return (
    <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 rounded-0 card order-lg-1">
      <div className="d-flex flex-center flex-column  "> {children}</div>
    </div>
  );
};

export const CreateJobHeader: FC<WithChildren> = ({ children }) => {
  return (
    <div className="w-100 h-150px">
      <div className="d-flex w-100 justify-content-end">{children}</div>
    </div>
  );
};
